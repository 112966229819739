<template>
  <nav
    class="mb-12 flex w-fit flex-col gap-4 text-button-15-regular-px md:mb-9px md:gap-6 lg:md:gap-12 lg:flex-row"
  >
    <NuxtLink
      v-for="item in data"
      :key="item.id"
      :to="
        item.reference
          ? localePath(`/${item.reference.slug}`)
          : item.externalLink
          ? item.externalLink
          : undefined
      "
      :target="item.externalLink ? '_blank' : undefined"
      class="transition-opacity hover:opacity-90"
      noPrefetch
    >
      {{ item.title }}
    </NuxtLink>
  </nav>
</template>

<script setup lang="ts">
import { FooterLinkRecord } from '~/types/generated'

const localePath = useLocalePath()

defineProps({
  data: {
    type: Array as PropType<FooterLinkRecord[]>
  }
})
</script>
