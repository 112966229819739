<template>
  <nav class="grid-default my-16 gap-y-10 md:m-0 md:gap-y-16">
    <SitemapItem
      v-for="item in parents"
      :key="item.id"
      :data="item"
      class="col-span-12 sm:col-span-4"
    />
  </nav>
</template>

<script setup lang="ts">
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

// Get the top level menu items
const parents = computed(() => props.data.filter(item => item.parent === null))
</script>
