<template>
  <button
    class="flex flex-col items-center gap-x-1 gap-y-4 text-button-18-medium sm:flex-row md:flex-col md:whitespace-nowrap md:text-[17px] lg:flex-row lg:text-button-20-medium"
    type="button"
  >
    <div class="flex items-center gap-x-1.5 self-start">
      <Icon name="globe-topics" class="h-5px w-5px md:h-7px md:w-7px" color="white" />
      <span>{{ $t('region.change') }}</span>
    </div>
    <div
      class="ml-0 mr-auto flex items-center gap-x-1"
      :class="isVisible ? 'text-orange' : 'text-white'"
    >
      <span>{{ currentLocale.countryName }} ({{ currentLocale.languageName }})</span>
    </div>
  </button>
</template>

<script setup>
import locales from '~/i18n/locales'
const { locale } = useI18n()

defineProps({
  isVisible: {
    type: Boolean,
    default: false
  }
})

const currentLocale = computed(() => locales.find(item => item.code === locale.value))
</script>
