<template>
  <div class="flex w-[fit-content] gap-4">
    <a
      v-for="item in filteredByCountry"
      :key="item.name"
      target="_blank"
      :title="item.title"
      rel="noopener noreferrer"
      :href="item.href"
    >
      <Icon :name="item.icon as IconName" class="h-8px w-8px" color="white" />
    </a>
  </div>
</template>

<script setup lang="ts">
import { IconName } from '~/components/media/Icon.vue'

const { t } = useI18n()

const config = useRuntimeConfig()

const links = [
  {
    name: 'Facebook',
    href: 'https://www.facebook.com/cryptonow.de',
    icon: 'facebook',
    title: t('social.links.facebook'),
    country: ['DE']
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/cryptonow.de',
    icon: 'instagram',
    title: t('social.links.instagram'),
    country: ['DE']
  },
  {
    name: 'linkedin',
    href: 'https://www.linkedin.com/company/93817418/admin/feed/posts/?feedType=following',
    icon: 'linkedin',
    title: t('social.links.linkedin'),
    country: ['CH', 'DE']
  }
]

const filteredByCountry = computed(() =>
  links.filter(item => item.country.includes(config.public.country))
)
</script>
