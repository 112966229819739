<template>
  <div class="text-[20px] md:text-[16px] lg:text-body-20-regular">
    <component
      :is="data.reference !== null ? NuxtLink : data.externalLink ? NuxtLink : 'span'"
      :to="
        data.reference
          ? localePath(`/${data.reference.slug}`)
          : data.externalLink
          ? data.externalLink
          : undefined
      "
      class="block font-medium hover:opacity-90 md:text-[17px] lg:text-heading-20-medium"
    >
      {{ data.title }}
    </component>
    <div
      v-if="Array.isArray(data.children) && data.children.length"
      class="mt-6 flex flex-col gap-4 md:gap-3px"
    >
      <NuxtLink
        v-for="item in childrenFiltered"
        :key="item.id"
        :to="
          item.reference
            ? localePath(`/${item.reference.slug}`)
            : item.externalLink
            ? item.externalLink
            : undefined
        "
        class="inline-block w-fit text-white opacity-70 transition-opacity hover:opacity-100"
        noPrefetch
        >{{ item.title }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
const NuxtLink = resolveComponent('NuxtLink')
const localePath = useLocalePath()

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

// Extract only published translations
const childrenFiltered = computed(() =>
  props.data.children.filter(x => x.publishTranslation === true)
)
</script>
